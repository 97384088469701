
// @ts-nocheck


export const localeCodes =  [
  "de",
  "en",
  "it"
]

export const localeLoaders = {
  "de": [],
  "en": [],
  "it": []
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "de",
      "iso": "de-DE",
      "name": "Deutsch"
    },
    {
      "code": "en",
      "iso": "en-US",
      "name": "English"
    },
    {
      "code": "it",
      "iso": "it-IT",
      "name": "Italiano"
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": true,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "https://welance.com",
  "dynamicRouteParams": true,
  "customRoutes": "config",
  "pages": {
    "focus-slug": {
      "de": "/focus/[slug]",
      "en": "/focus/[slug]",
      "it": "/focus/[slug]"
    },
    "preview-langIso-id": {
      "de": "/preview/[langIso]/Pages/[id]",
      "en": "/preview/[langIso]/Pages/[id]",
      "it": "/preview/[langIso]/Pages/[id]"
    },
    "we-preview-langIso-id-version": {
      "de": "/we/preview/[langIso]/Pages/[id]/[version]",
      "en": "/we/preview/[langIso]/Pages/[id]/[version]",
      "it": "/we/preview/[langIso]/Pages/[id]/[version]"
    },
    "home": {
      "de": "/",
      "en": "/",
      "it": "/"
    },
    "slug": {
      "de": "/[slug]",
      "en": "/[slug]",
      "it": "/[slug]"
    },
    "imprint": {
      "de": "/impressum",
      "en": "/imprint",
      "it": "/imprint"
    },
    "privacy": {
      "de": "/datenschutz",
      "en": "/privacy",
      "it": "/privacy"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "de",
    "iso": "de-DE",
    "name": "Deutsch",
    "files": []
  },
  {
    "code": "en",
    "iso": "en-US",
    "name": "English",
    "files": []
  },
  {
    "code": "it",
    "iso": "it-IT",
    "name": "Italiano",
    "files": []
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
