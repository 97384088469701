export default defineNuxtRouteMiddleware((to) => {
  // Define a blacklist to exclude specific routes
  const BLACKLIST: (string | RegExp)[] = [
    '/focus/**', // Matches `/focus/technology`, `/focus/design`
    '/cdn-cgi/**', // Matches `/cdn-cgi/path/`
    '/exact-match', // Matches `/exact-match`
    /^\/assets\/[a-z0-9-]+$/, // Matches `/assets/some-id`
  ]

  // Detect i18n language prefixes like `/en/`, `/de/`, etc.
  const i18nPrefixRegex = /^\/[a-z]{2}\//

  // Helper function to match a path against a wildcard pattern
  const matchesWildcard = (path: string, pattern: string): boolean => {
    const regex = new RegExp(
      '^' +
        pattern
          .replace(/\*\*/g, '.*?') // ** matches any character, 0 or more times
          .replace(/\*/g, '[^/]*') // * matches any character except /
          .replace(/\?/g, '.') + // ? matches any single character
        '$'
    )
    return regex.test(path)
  }

  // Remove i18n language prefix from the path for matching
  const normalizedPath = to.fullPath.replace(i18nPrefixRegex, '/')

  // Function to check if the current route matches any blacklist pattern
  const isBlacklisted = (path: string): boolean => {
    return BLACKLIST.some((entry) => {
      if (typeof entry === 'string') {
        return matchesWildcard(path, entry)
      } else if (entry instanceof RegExp) {
        return entry.test(path)
      }
      return false
    })
  }

  // Skip handling for blacklisted routes
  if (isBlacklisted(normalizedPath)) {
    console.log(
      `Bypassing route: ${to.fullPath} (normalized as ${normalizedPath})`
    )
    // return // Allow Nuxt to handle specific routes like `/focus/**` in their own files
  }

  // Handle trailing slash redirection (ignore root path `/`)
  if (to.path !== '/' && to.path.endsWith('/')) {
    const newPath = to.path.slice(0, -1) // Remove trailing slash
    console.log(`Redirecting to remove trailing slash: ${newPath}`)
    return navigateTo({ ...to, path: newPath, params: to.params })
  }
})
